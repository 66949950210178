import {LoadAfterConfirmationEvents} from "../../page/elements/loadAfterConfirmation";
import {Resolution} from "../../common/resolution";
import {MapContainer} from "./map";
import {isDefined} from "../../common/utils/basics";
import {isString} from "../../bootstrap/common/strings";
import {autoRegister, resolve} from "../../container";
import {Cluster, ClusterStats, DefaultRenderer} from "@googlemaps/markerclusterer";
import {EOP_ERRORS} from "../../common/utils/promises";
import {GoogleMapsRunner} from "./maps";
import {GoogleMapsFactory} from "./googleMapsFactory";
import {elementFrom} from "../../common/utils/html";

//REMINDER (@dammg): Remove this file after all instances of old google maps are deleted in FirstSpirit

export type GoogleMapsConfigSrc = {
    element?: HTMLElement;
    pois?: GoogleMapsPoiSrc[];
    zoom?: string;
};

export type GoogleMapsPoiSrc = {
    infoContent?: string;
    title?: string;
    coords?: CoordsSrc;
};

type CoordsSrc = {
    lng?: string;
    lat?: string;
};

export class GoogleMapsConfig {
    public element: HTMLElement;
    public pois: GoogleMapsPoi[] = [];
    public zoom: number = 14;

    public constructor(element: HTMLElement) {
        this.element = element;
    }
}

class GoogleMapsPoi {
    public pos: Coords;
    public title: string;
    public infoContent: string;
    public color: string;

    public constructor(pos: Coords, title?: string, infoContent?: string) {
        this.pos = pos;
        this.title = title ?? "";
        this.infoContent = infoContent ?? "";
    }
}

export class Coords {
    public lng: number;
    public lat: number;

    public constructor(lng: number, lat: number) {
        this.lng = lng;
        this.lat = lat;
    }
}

@autoRegister()
export class GoogleMapsService {

    public constructor(
        private resolution: Resolution = resolve(Resolution),
        private googleMapsFactory: GoogleMapsFactory = resolve(GoogleMapsFactory)
    ) {
    }

    public showMap(config: GoogleMapsConfigSrc): void {
        if (!config.element) {
            return;
        }
        const mapConfig = new GoogleMapsConfig(config.element);

        const pois = this.preprocessPois(config.pois);
        if (pois.length === 0) {
            return;
        }
        mapConfig.pois = pois;

        if (isString(config.zoom)) {
            mapConfig.zoom = parseInt(config.zoom);
        }

        const mapContainer = this.initMapContainer(mapConfig.element, pois[0].pos, mapConfig.zoom);
        this.initPois(mapContainer, pois);
        this.initMarkerClusterer(mapContainer);
    }

    private preprocessPois(srcPois?: GoogleMapsPoiSrc[]): GoogleMapsPoi[] {
        if (!Array.isArray(srcPois)) {
            return [];
        }
        const pois: GoogleMapsPoi[] = [];
        srcPois.forEach(poi => {
            if (!poi.coords?.lat || !poi.coords?.lng) {
                return;
            }
            const coords = new Coords(parseFloat(poi.coords.lng), parseFloat(poi.coords.lat));
            pois.push(new GoogleMapsPoi(coords, poi.title, poi.infoContent));
        });

        return pois;
    }

    private initMapContainer(element: HTMLElement, center: Coords, zoom: number): MapContainer {
        const map = new google.maps.Map(element, {
            center: center,
            zoom: zoom,
            mapTypeControl: true,
            scaleControl: true,
            mapId: "DEMO_MAP_ID"
        });

        this.resolution.onWindowResize(() => {
            map.setCenter(center);
        });

        return new MapContainer(map);
    }

    private initPois(mapContainer: MapContainer, pois: GoogleMapsPoi[]): void {
        mapContainer.removePois();

        pois.forEach((poi) => {
            this.addPoi(mapContainer, poi);
        });
    }

    private addPoi(mapContainer: MapContainer, poi: GoogleMapsPoi): void {
        const marker = this.initMarker(mapContainer, poi);
        mapContainer.pois.push(marker);

        this.initInfoWindow(mapContainer, marker, poi);
    }

    private initMarker(mapContainer: MapContainer, poi: GoogleMapsPoi): google.maps.marker.AdvancedMarkerElement {
        return new google.maps.marker.AdvancedMarkerElement({
            map: mapContainer.map,
            position: poi.pos,
            title: poi.title,
            content: this.pinIcon()
        });
    }

    private pinIcon(): Element {
        const width = 30;
        const height = 35;
        const svg = `<svg xmlns="http://www.w3.org/2000/svg" 
                                    width="${width}px" height="${height}px" viewBox="0 0 ${width + 5} ${height + 5}" 
                                    fill="#009"
                                    fill-opacity="1"
                                    fill-rule="evenodd"
                                    stroke="#fff" 
                                    stroke-width="2"
                                    stroke-opacity="1"
                                 >
                                    <g>
                                        <path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" transform="scale(1.5)"></path>
                                    </g>
                                 </svg>`;
        return elementFrom(svg);
    }


    private initInfoWindow(mapContainer: MapContainer, marker: google.maps.marker.AdvancedMarkerElement, poi: GoogleMapsPoi): void {
        const enbwInfoContent = elementFrom("<div class='enbw-info-window-content'>" + poi.infoContent + "</div>");
        const enbwInfoWindow = new google.maps.InfoWindow({
            content: enbwInfoContent
        });

        marker.addListener("click", () => {
            enbwInfoWindow.open(mapContainer.map, marker);
        });

        mapContainer.infoWindows.push(enbwInfoWindow);
    }

    private initMarkerClusterer(mapContainer: MapContainer): void {
        this.googleMapsFactory.createMarkerClusterer(mapContainer.map, mapContainer.pois, new CustomClusterRenderer());
    }
}

export class CustomClusterRenderer extends DefaultRenderer {
    public render(cluster: Cluster, stats: ClusterStats): google.maps.marker.AdvancedMarkerElement {
        return new google.maps.marker.AdvancedMarkerElement({
            position: cluster.position,
            content: this.circleIcon(String(cluster.count)),
            zIndex: 1000 + cluster.count
        });
    }

    private circleIcon(label: string): Element {
        const width = 60;
        const height = 60;
        const svg = `
            <svg xmlns="http://www.w3.org/2000/svg" 
                width="60px" height="${height}px" cursor="pointer" viewBox="0 0 ${width} ${height}"
                fill="#009"
                fill-opacity="1"
                fill-rule="evenodd"
                stroke="#fff" 
                stroke-width="2"
                stroke-opacity="1"
                text-anchor="start"
             >
                <circle cx="${width / 2}" cy="${height / 2}" r="26" />
                <text fill="#fff" 
                font-weight="200" font-size="1.2em" x="${width / 2}" y="${(height / 2) + 5}" text-anchor="middle" stroke-width="1">${label}</text>
             </svg>
            `;
        return elementFrom(svg);
    }
}

export class EopGoogleMaps extends HTMLElement {
    public constructor(
        private loadAfterConfirmationEvents: LoadAfterConfirmationEvents = resolve(LoadAfterConfirmationEvents),
        private googleMapsService: GoogleMapsService = resolve(GoogleMapsService),
        private googleMapsRunner: GoogleMapsRunner = resolve(GoogleMapsRunner)
    ) {
        super();
    }

    public connectedCallback(): void {
        this.loadAfterConfirmationEvents.onConfirmation("googlemaps", () => {
            return this.googleMapsRunner.run().then(() => {
                this.showMap();
            }).catch(EOP_ERRORS);
        });
    }

    private showMap(): void {
        const config: GoogleMapsConfigSrc = {
            element: this.querySelector<HTMLElement>(".enbw-google-maps")!
        };
        const configId = this.getAttribute("config")!;
        const importedConfig = this.readRawData<GoogleMapsConfigSrc>(configId);
        if (importedConfig) {
            if (Array.isArray(importedConfig.pois) && importedConfig.pois.length > 0) {
                config.pois = importedConfig.pois;
            }

            if (isDefined(importedConfig.zoom)) {
                config.zoom = importedConfig.zoom;
            }
        }

        this.googleMapsService.showMap(config);
    }
}

customElements.define("eop-google-maps", EopGoogleMaps);