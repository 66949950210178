export class MapContainer {
    public userMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
    public pois: google.maps.marker.AdvancedMarkerElement[] = [];
    public infoWindows: google.maps.InfoWindow[] = [];

    public constructor(public map: google.maps.Map) {
    }

    private resetMarker(marker: google.maps.marker.AdvancedMarkerElement): void {
        marker.map = null;
    }

    public removePois(): void {
        this.pois.forEach(this.resetMarker);

        this.pois = [];
        this.infoWindows = [];
    }

    public removeUserMarkers(): void {
        this.userMarkers.forEach(this.resetMarker);

        this.userMarkers = [];
    }
}