import {Cluster, DefaultRenderer} from "@googlemaps/markerclusterer";
import {customElement, property, query} from "lit/decorators.js";
import {LoadAfterConfirmationEvents} from "../../page/elements/loadAfterConfirmation";
import {autoRegister, resolve} from "../../container";
import {Resolution} from "../../common/resolution";
import {Deferred, EOP_ERRORS} from "../../common/utils/promises";
import type {PropertyMap} from "../../common/utils/objects";
import {Configuration} from "../../common/config";
import {Load} from "../../common/load";
import {GoogleMapsFactory} from "./googleMapsFactory";
import {UrlBuilder} from "../../common/utils/url";
import {ManagingResources} from "../../common/lifetime";
import {UnLitElement} from "../../common/elements";
import {GLOBAL} from "../../common/globals";
import {elementFrom} from "../../common/utils/html";
import AdvancedMarkerElement = google.maps.marker.AdvancedMarkerElement;

const COLOR_WHITE = "--marker-color-white";
const COLOR_PRIMARY = "--marker-color-primary";
const COLOR_SECONDARY = "--marker-color-secondary";
const COLOR_GRAY = "--marker-color-gray";
const COLOR_HIGHLIGHT = "--marker-color-highlight";

const MARKER_COLOR_PROPS: PropertyMap<string> = {
    primary: COLOR_PRIMARY,
    secondary: COLOR_SECONDARY,
    gray: COLOR_GRAY
};

export type GoogleMapsWindow = Window & {
    onGoogleMapsReady: () => void;
};

type LatLngLiteral = {
    lat: number;
    lng: number;
};

@autoRegister()
export class GoogleMapsRunner {
    private readonly GOOGLEMAPS_URL: string | undefined;
    private readonly GOOGLEMAPS_CLIENTID: string | undefined;
    private clientDeferred: Deferred<void>;
    private isLoaded: boolean;
    private window: GoogleMapsWindow;

    public constructor(
        private config: Configuration = resolve(Configuration),
        private load: Load = resolve(Load)
    ) {
        this.clientDeferred = new Deferred<void>();
        this.GOOGLEMAPS_URL = this.config.get("GOOGLEMAPS_URL");
        this.GOOGLEMAPS_CLIENTID = this.config.get("GOOGLEMAPS_CLIENTID");
        this.isLoaded = false;
        this.window = GLOBAL.window() as GoogleMapsWindow;
    }

    public run(): Promise<void> {
        if (this.isLoaded) {
            return this.clientDeferred.promise;
        }
        if (this.GOOGLEMAPS_URL && this.GOOGLEMAPS_CLIENTID) {
            this.isLoaded = true;
            this.window.onGoogleMapsReady = () => {
                this.clientDeferred.resolve();
            };
            const apiUrl = new UrlBuilder(this.GOOGLEMAPS_URL)
                .withQueryParameter("client", this.GOOGLEMAPS_CLIENTID)
                .withQueryParameter("callback", "onGoogleMapsReady")
                .withQueryParameter("loading", "async")
                .withQueryParameter("libraries", "marker")
                .build();

            void this.load.script(apiUrl);
        } else {
            this.clientDeferred.reject();
        }

        return this.clientDeferred.promise;
    }
}

export class EopCustomClusterRenderer extends DefaultRenderer {
    private googleMapsFactory: GoogleMapsFactory = resolve(GoogleMapsFactory);

    public constructor(private strokeColor: string, private fillColor: string) {
        super();
    }

    public render(cluster: Cluster): AdvancedMarkerElement {
        return this.googleMapsFactory.createMarker({
            position: cluster.position,
            content: this.circleIcon(String(cluster.count)),
            zIndex: 1000 + cluster.count
        });
    }

    private circleIcon(label: string): Element {
        const width = 60;
        const height = 60;
        const svg = `
            <svg xmlns="http://www.w3.org/2000/svg" 
                width="60px" height="${height}px" cursor="pointer" viewBox="0 0 ${width} ${height}"
                fill="${this.fillColor}"
                fill-opacity="1"
                fill-rule="evenodd"
                stroke="${this.strokeColor}" 
                stroke-width="2"
                stroke-opacity="1"
                text-anchor="start"
             >
                <circle cx="${width/2}" cy="${height/2}" r="26" />
                <text fill="${this.strokeColor}" 
                font-weight="200" font-size="1.2em" x="${width/2}" y="${(height / 2) + 5}" text-anchor="middle" stroke-width="1">${label}</text>
             </svg>
            `;
        return elementFrom(svg);
    }
}

@customElement("eop-google-map")
export class EopGoogleMap extends ManagingResources(UnLitElement) {

    private mapCenter: google.maps.LatLng | undefined;
    private map: google.maps.Map;
    private pois: EopGoogleMapsPoi[];
    private initialized: boolean;
    private googleMapsElement: HTMLElement | null;

    @property({attribute: "zoom", type: Number})
    public zoom: number = 10;
    @property({attribute: "map-type"})
    public mapType: string = "roadmap";
    @property({attribute: "clustering", type: Boolean})
    public useClustering: boolean = false;


    public constructor(
        private loadAfterConfirmationEvents: LoadAfterConfirmationEvents = resolve(LoadAfterConfirmationEvents),
        private googleMapsRunner: GoogleMapsRunner = resolve(GoogleMapsRunner),
        private resolution: Resolution = resolve(Resolution),
        private googleMapsFactory: GoogleMapsFactory = resolve(GoogleMapsFactory)
    ) {
        super();

        this.initialized = false;
        this.pois = [];
    }

    public connectedCallback(): void {
        this.loadAfterConfirmationEvents.onConfirmation("googlemaps", () => {
            return this.googleMapsRunner.run()
                .then(() => this.initMap())
                .catch(EOP_ERRORS);
        });
    }

    private initMap(): void {
        if (this.initialized) {
            return;
        }
        this.initialized = true;

        this.googleMapsElement = this.querySelector<HTMLElement>(".google-maps-element")!;
        const pois = this.querySelectorAll<EopGoogleMapsPoi>("eop-google-maps-poi");
        this.mapCenter = pois.length === 0 ? undefined : pois.item(0).position();
        this.map = this.googleMapsFactory.createMap(this.googleMapsElement, {
            center: this.mapCenter,
            zoom: this.zoom,
            mapTypeControl: true,
            scaleControl: true,
            mapTypeId: this.mapType,
            mapId: "DEMO_MAP_ID"
        });

        this.resolution.onWindowResize(() => {
            if (this.mapCenter) {
                this.map.setCenter(this.mapCenter);
            }
        }, this);

        pois.forEach((poi) => {
            this.addMarker(poi);
            this.pois.push(poi);
        });

        this.map.addListener("click", () => {
            this.pois.forEach(p => p.close());
        });

        this.googleMapsElement.firstElementChild!.prepend(this.querySelector(".pois")!);

        if (this.useClustering) {
            this.googleMapsFactory.createMarkerClusterer(this.map, this.pois.flatMap(p => p.marker),
                new EopCustomClusterRenderer(this.getComputedColor(COLOR_WHITE), this.getComputedColor(COLOR_PRIMARY)));
        }
    }

    private addMarker(poi: EopGoogleMapsPoi): void {
        const marker = poi.renderOnMap(this.map);

        marker.addListener("click", () => {
            this.pois.filter(p => p.isOpen() && p !== poi)
                .forEach(p => p.close());
            poi.toggleOpen();

            this.centerMapOnMarker(marker, poi);
        });
    }

    private centerMapOnMarker(marker: AdvancedMarkerElement, poi: EopGoogleMapsPoi): void {
        const markerCenter = this.calculateCenterWithSidebarOffset(poi, marker.position);
        if (markerCenter) {
            this.mapCenter = markerCenter;
            this.map.panTo(this.mapCenter);
        }
    }

    private calculateCenterWithSidebarOffset(poi: EopGoogleMapsPoi, markerCenter?: unknown): google.maps.LatLng | undefined {
        const mapBounds = this.map.getBounds();
        if (!markerCenter || !mapBounds) {
            return undefined;
        }
        const lngSpan = mapBounds.toSpan().lng() / 2;
        const ratioLngPerPixel = lngSpan !== 0
            ? lngSpan / this.googleMapsElement!.firstElementChild!.clientWidth
            : this.clientWidth;
        const [lat, lng] = (markerCenter instanceof google.maps.LatLng) ? [markerCenter.lat(), markerCenter.lng()] : (() => {
            const it = markerCenter as LatLngLiteral;
            return [it.lat, it.lng];
        })();
        return this.googleMapsFactory.createLatLng(lat, lng - (poi.clientWidth * ratioLngPerPixel));
    }

    private getComputedColor(propertyName: string): string {
        return getComputedStyle(this).getPropertyValue(propertyName);
    }
}

@customElement("eop-google-maps-poi")
export class EopGoogleMapsPoi extends UnLitElement {
    @property({attribute: "visual-data-title"})
    public title: string;
    @property({attribute: "color"})
    private color: string;
    @property({attribute: "lat", type: Number})
    private lat: number;
    @property({attribute: "lng", type: Number})
    private lng: number;
    @query(".close-button")
    private closeButton: HTMLElement;

    public marker: AdvancedMarkerElement;

    public constructor(
        private googleMapsFactory: GoogleMapsFactory = resolve(GoogleMapsFactory)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.closeButton.addEventListener("click", () => this.close());
    }

    public renderOnMap(map: google.maps.Map): AdvancedMarkerElement {
        this.marker = this.googleMapsFactory.createMarker({
            map: map,
            position: this.position(),
            title: this.title,
            content: this.pinIcon()
        });
        return this.marker;
    }

    public position(): google.maps.LatLng {
        return this.googleMapsFactory.createLatLng(this.lat, this.lng);
    }

    public isOpen(): boolean {
        return this.classList.contains("open");
    }

    public toggleOpen(): void {
        this.isOpen() ? this.close() : this.open();
    }

    public open(): void {
        this.classList.add("open");
        if (this.marker) {
            this.marker.content = this.pinIcon(COLOR_HIGHLIGHT);
        }
    }

    public close(): void {
        this.classList.remove("open");
        if (this.marker) {
            this.marker.content = this.pinIcon();
        }
    }

    private getComputedColor(propertyName: string): string {
        return getComputedStyle(this).getPropertyValue(propertyName);
    }

    private pinIcon(fillColor?: string): Element {
        const width = 30;
        const height = 35;
        const svg = `<svg xmlns="http://www.w3.org/2000/svg" 
                                    width="${width}px" height="${height}px" viewBox="0 0 ${width + 5} ${height + 5}" 
                                    fill="${this.getComputedColor(fillColor ?? MARKER_COLOR_PROPS[this.color] ?? COLOR_PRIMARY)}"
                                    fill-opacity="1"
                                    fill-rule="evenodd"
                                    stroke="${this.getComputedColor(COLOR_WHITE)}" 
                                    stroke-width="2"
                                    stroke-opacity="1"
                                 >
                                    <g>
                                        <path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" transform="scale(1.5)"></path>
                                    </g>
                                 </svg>`;
        return elementFrom(svg);
    }
}